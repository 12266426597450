/**
 * Default page javascript. All other pages extend this one.
 */
import "core-js/modules/es.promise";
import "bootstrap/js/modal";
import "bootstrap/js/collapse";
import "bootstrap/js/transition";
import "lazysizes";

// Polyfill
import arrayFindPolyfill from "./arrayFind";

// Libs
import aosInit from "./aos";
import whenDomReady from "when-dom-ready";
import scrollSpy from "./scrollSpy";
import siteConfig from "./siteConfig";
import coreHeaderNav from "./coreHeaderNav";
import extend from "./extend";
import LoadScript from "../plugins/loadScript";
import CoreIsiSticky from "./coreIsiSticky";
import stickyHeader from "./stickyHeader";
import stickyHeaderMobile from "./stickyHeaderMobile";
import BtnCollapsible from "./btnCollapsible";
import PreFilledPen from "./preFilledPen";
import SingleVideoTranscript from "./singleVideoTranscript";
import ImageComparisonSlider from "./imageComparisonSlider";
import scrollNav from "./scrollNav";
import EmailDocModalSubmit from "./emailDocModalSubmit";
import TableCustom from "./tableCustom";
import noticeBar from "./noticeBar";
import ResultPercent from "./resultPercent";
import setIndicationCookie from "./setIndicationCookie";
import videoGalleryTranscriptScroll from "./videoGalleryTranscriptScroll";
import coreInterstitials from "./coreInterstitials";
import authorizationTray from "./authorizationTray";
import contactRep from "./contactRep";
import FilterGalleryForm from "./filterGalleryForm";
import verticalAccordion from "./verticalAccordion/verticalAccordion";
import requestRepMain from "./RequestRep/requestRepMain";
import homeCarousel from "./homeCarousel";
import video from "./video";
import patientProfiles from "./patientProfiles";

// Styles
import "../../css/pages/BasePage.scss";
import References from "./references";
import documentMailer from "./documentMailer";

//Alpine
import Alpine from "alpinejs";
import mask from "@alpinejs/mask";
import isi from "./isi";

import AsyncAlpine from "async-alpine";
import vimeoPlayer from "./video/vimeo";
import scrollGradient from "./scrollGradient";

class BasePage {
    constructor(...opts) {
        extend(this, opts);
        window.Alpine = Alpine;
        Alpine.plugin(mask);
        AsyncAlpine.init(Alpine);
        Alpine.data("vimeoPlayer", vimeoPlayer);
        Alpine.data("scrollGradient", scrollGradient);
        Alpine.data("patientProfiles", patientProfiles);
        Alpine.data("isi", isi);
        AsyncAlpine.data("verticalAccordionSingle", () => import("./verticalAccordion/verticalAccordionSingle"));
        AsyncAlpine.data("seeMore", () => import("./seeMore/index.js"));
        AsyncAlpine.data("tab", () => import("./tab/index.js"));
        AsyncAlpine.start();
        Alpine.start();
        this.init();
    }

    init() {
        const self = this;
        self.ready = false;
        self.onBeforeReady();
        setIndicationCookie.init();

        whenDomReady().then(function() {
            self.onReady();
        });

        window.addEventListener("load", function() {
            self.onLoad();
            EmailDocModalSubmit.init();
            videoGalleryTranscriptScroll.init();
            CoreIsiSticky.init();
            coreInterstitials.init();
            video.init();
        });
    }

    onBeforeReady() {
        extend(this, siteConfig);
        arrayFindPolyfill();
        // load & append new script
        new LoadScript();
    }

    onReady() {
        const self = this;
        self.ready = true;
        new BtnCollapsible();
        new References();
        new PreFilledPen();
        new ImageComparisonSlider();
        coreHeaderNav.init();
        scrollSpy.init();
        stickyHeader.init();
        stickyHeaderMobile.init();
        authorizationTray.init();
        new SingleVideoTranscript();
        scrollNav.init();
        documentMailer();
        noticeBar.init();
        contactRep.init();
        new ResultPercent();
        new FilterGalleryForm();
        verticalAccordion();
        requestRepMain();
        homeCarousel.loadCarousel();
        aosInit();
    }

    onLoad() {
        document.getElementsByTagName("body")[0].classList.add("loaded");
        new TableCustom();
    }

    isReady() {
        var self = this;
        return self.ready === true;
    }

    noop() {}
}

export default BasePage;
